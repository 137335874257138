<template>
  <div class="row" ref="proxy">
    <div class="col-md-12 col-lg-12">
      <c-card title="이름을 정자로 입력하세요." class="cardClassDetailInfo" topClass="topcolor-orange">
        <template v-if="data.recipientSign" slot="card-description">
          <font color="#C10015">※ 서명 완료되었습니다.</font>
        </template>
        <template slot="card-detail">
          <div class="row">
            <div class="col-xs-12 col-sm-12">
              <VueSignaturePad id="signature" width="100%" height="200px" ref="signaturePad" />
            </div>
            <div class="col-12" v-if="data.recipientSign">서명제출일시 : {{ data.signDt ? data.signDt.slice(0, 19) : '' }}</div>
          </div>
        </template>
      </c-card>
    </div>
    <c-card title="보호구 지급 기본정보" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-12">
          <c-plant
            :disabled="true"
            name="plantCd"
            label="사업장"
            v-model="data.plantCd"
          ></c-plant>
        </div>
        <div class="col-12">
          <c-text
            v-show="data.giveTypeCd=='SGT0000001'"
            :disabled="true"
            name="tbmName"
            label="신청 부서/신청자/일자"
            v-model="request"
          ></c-text>
        </div>
        <div class="col-12">
          <c-text
            :disabled="true"
            name="tbmName"
            label="지급 부서/불출자/일자"
            v-model="give"
          ></c-text>
        </div>
      </template>
    </c-card>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="보호구 지급 목록"
        :isTitle="true"
        :columns="grid.columns"
        :data="data.detailList2"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        :editable="editable && popupParam.requestGiveInfoId && !data.recipientSign && data.statusCd!='SSC0000015'"
        noDataLabel="지급할 보호구가 없습니다."
        gridHeighAuto
        rowKey="giveListId"
      ></c-table>
    </div>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn flat v-if="!data.recipientSign" label="수령완료" 
          color="blue" :showLoading="false" @btnClicked="saveSign" />
          <c-btn flat v-if="!data.recipientSign" label="서명삭제" color="red" :showLoading="false" @btnClicked="eraseSign" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>
<script>
import VueSignaturePad from 'vue-signature-pad';
import Vue from 'vue';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
Vue.use(VueSignaturePad)
export default {
  name: 'signature-pop',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          requestGiveInfoId: '',
        }
      },
    },
  },
  data() {
    return {
      editable: true,
      updateSignUrl: '',
      getUrl: '',
      data: {
        requestGiveInfoId: '',
        plantCd: null,
        requestDate: '',
        requestDeptCd: '',
        requestDeptName: '',
        requestUserName:'',
        requestUserId: '',
        requestItems: '',
        giveItems: '',
        giveDate: '',
        giveTypeCd: '',
        giveDeptCd: '',
        giveUserId: '',
        giveTypeName: '',
        giveDeptName: '',
        giveUserName: '',
        statusCd: '',
        statusName: '',
        sysApprovalRequestId: '',
        recipientId: '',
        recipientName: '',
        recipientSign: '',
        signDt: '',
        detailList: [],
        deleteDetailList: [],
        detailList2: [],
        deleteDetailList2: [],
      },
      grid: {
        columns: [
          {
            name: 'safetyGearName',
            field: 'safetyGearName',
            label: '보호구명',
            style: 'width:25%',
            align: 'left',
            sortable: false,
          },
          {
            name: 'giveCount',
            field: 'giveCount',
            label: '지급수량',
            style: 'width:25%',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            style: 'width:25%',
            align: 'left',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return Boolean(this.data.recipientSign);
    },
    request() {
      return (this.data.requestDeptName?this.data.requestDeptName+' / ':'')+(this.data.requestUserName?this.data.requestUserName+' / ':'')+(this.data.requestDate?this.data.requestDate:'');
    },
    give() {
      return (this.data.giveDeptName?this.data.giveDeptName+' / ':'')+(this.data.giveUserName?this.data.giveUserName+' / ':'')+(this.data.giveDate?this.data.giveDate:'');
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.updateSignUrl = transactionConfig.sop.sas.give.sign.url;
      this.getUrl = selectConfig.sop.sas.give.get.url;
      this.getUserDetail();
      if (!this.$refs.signaturePad) {return }
    },
    getUserDetail() {
      if (this.popupParam.requestGiveInfoId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.requestGiveInfoId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data) {
            this.data = _result.data
            if(_result.data.giveTypeCd=='SGT0000001') {
              this.grid.columns.splice(1, 0, 
                {
                  name: 'requestCount',
                  field: 'requestCount',
                  label: '신청수량',
                  style: 'width:80px',
                  align: 'right',
                  type: 'cost',
                  sortable: false,
                },
              )
            }
            if (this.data.recipientSign) {
            this.$refs.signaturePad.fromDataURL(this.data.recipientSign);
            this.$refs.signaturePad.lockSignaturePad(); }
          } else {
            this.data.recipientId = this.$store.getters.user.userId
          }
        })
      }
    },
    saveSign() {
      if(this.$refs.signaturePad.saveSignature()) {this.data.recipientSign = this.$refs.signaturePad.saveSignature().data }
      if (!this.data.recipientSign) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '서명하세요.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '서명등록하시겠습니까? \n(등록 시 보호구 수령 처리됩니다.)',
          type: 'warning', // success / info / warning / error
          confirmCallback: () => {
            this.data.chgUserId = this.$store.getters.user.userId
            this.$http.url = this.updateSignUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.data;
            this.$http.request((_result) => {
              this.popupParam.requestGiveInfoId = _result.data;
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              // this.$emit('closePopup');
              this.getUserDetail();
            },);
          },
          cancelCallback: () => {
          },
      });
      }
    },
    eraseSign() {
      this.$refs.signaturePad.clearSignature();
      this.data.recipientSign = '';
    },
    reset() {
      Object.assign(this.$data.info, this.$options.data().info);
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>
<style>
#signature {
  border: double 1px transparent;
  border-radius: 2;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>